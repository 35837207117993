<template>

  <div class="container">
    <div class="roomle" >
      <div v-if="!forScreenshotService && (isLoaded && afterLoadEffectVisible)" class="afterloadeffect_div" @click="reactToClick()">
        <div class="afterloadeffect_position" @click="reactToClick();"></div>
        <div v-if="afterLoadEffectTextVisible" class="aftereffect_text" @click="reactToClick();">or click to zoom</div>
      </div>
      <div id="scene" ref="scene" class="scene scene-center scene-outline" @click="reactToClick();"></div>
<!--      <div v-if="!isLoaded" class="loaderContainer">-->
<!--        <div class="loader"></div>-->
<!--      </div>-->
      <div v-if="!forScreenshotService && !isLoaded" class="preview" :style="{ backgroundImage: 'url(' + previewImage + ')', backgroundSize: previewImageSize }"></div>
<!--      <div class="loaderContainer" :style="{ display: rmlImg ? 'flex' : 'none' }">-->
<!--        <div class="loader"></div>-->
<!--      </div>-->

    </div>

  </div>


</template>

<script>

import {onMounted, ref, reactive, watch, onBeforeUnmount} from "vue";
import RoomleSdk from "@roomle/web-sdk";
import { defaultRoomleConfig } from "@/config/roomle";
import { defaultSettings } from "@/config/camera";
import { BlindIdMap } from "@/config/blindidmap";
import { BlindIdMap2 } from "@/config/blindidmap2";
import { RolloIdMap } from "@/config/rolloidmap";
import { LamellaIdMap } from "@/config/lamellaidmap";
import { LadderTapeIdMap } from "@/config/laddertapeidmap";
import { PlisseeIdMap } from "@/config/plisseeidmap";
import { BasicPlisseeIdMap } from "@/config/basicplisseeidmap";

let frameLoaded = false;
let initRunning = false;

export default {
  name: "Scene",
  props: {},
  setup: function () {
    let useOffsets = true;

    const doCustomZooms = 'on';
    const customZoomDelay = 1200;
    const customZoomResetTime = 5900;
    let customZoomTimeout = null;
    let customZoomResetTimeout = null;
    let lastZoomCheck = null;

    const devlog = {
      debug: false,
      info: false,
      warn: false,
      rcvmsg: true,
      events: false,
    }

    let paramUpdateRunning = false;
    let rmlImg = ref(false);

    let background = 'Ohne';

    const isLoaded = ref(false);
    const afterLoadEffectVisible = ref(false);
    const afterLoadEffectTextVisible = ref(false);

    const previewImage = ref("");
    const previewImageSize = ref("0%");

    let userInteraction = false;
    let isUpdating = false;

    const scene = ref(null);

    let roomleConfig = {...defaultRoomleConfig};

    let roomleConfiguratorApi = null;
    let roomleConfigurator = null;

    let zoomed = false;
    let draggingX = 0;
    let draggingY = 0;

    const origSettings = {...defaultSettings};

    let lastAppliedCameraSetting = {};

    let sceneStyle = reactive({
      width: origSettings.width,
      height: origSettings.height,
    });

    let cameraSettings = reactive(
        {
          yaw: origSettings.yaw,
          pitch: origSettings.pitch,
          distance: origSettings.distance,
          targetX: origSettings.targetX,
          targetY: origSettings.targetY,
          targetZ: origSettings.targetZ,
        }
    )

    const wallOn = function() {
      console.warn('wall on');
      updateParameters({ 'Hintergrund': "Mit" })
    }
    const wallOff = function() {
      console.warn('wall off');
      updateParameters({ 'Hintergrund': "Ohne" });
    }
    const  getParentQueryString = function() {
      var queryStringKeyValue = window.parent.location.search.replace('?', '').split('&');
      var qsJsonObject = {};
      if (queryStringKeyValue != '') {
        for (let i=0; i < queryStringKeyValue.length; i++) {
          qsJsonObject[queryStringKeyValue[i].split('=')[0]] = queryStringKeyValue[i].split('=')[1];
        }
      }
      return qsJsonObject;
    }


    const drag =  (event) => {
      return Math.abs( draggingX - event.screenX) > 3 || Math.abs( draggingY - event.screenY) > 3
    }

    const doZoom = async  (event) => {
      if (!drag(event)) {
        if(devlog.debug) console.log('zooming');
        if (zoomed) {
          zoomed = false;
          if(devlog.debug)  console.log(lastAppliedCameraSetting);
          roomleConfiguratorApi.moveCamera(lastAppliedCameraSetting);
        } else {
          const params = await roomleConfiguratorApi.getParametersOfRootComponent();
          let withWall = params.find((param) => param.key === "Hintergrund").value==="Mit";
          let productWidth = (parseInt(params.find((param) => param.key === "Width").value) /1000) + (withWall ? 0.28 : 0);
          let productHeight = (parseInt(params.find((param) => param.key === "Height").value) /1000) + (withWall ? 0.4 : 0);

          let offset = 13 * window.innerHeight/465;/*px*/
          if (withWall) offset *= 2; /*px*/

          let clickableWidth = 0;  let clickableHeight = 0;

          if (window.innerWidth /  window.innerHeight < productWidth / productHeight) {
            clickableHeight = window.innerWidth* (productHeight/productWidth)-2*offset;
            clickableWidth = window.innerWidth-2*offset;
          } else {
            clickableWidth = window.innerHeight* (productWidth/productHeight) -2*offset;
            clickableHeight = window.innerHeight-2*offset;
          }

          let xNorm = (event.clientX - (window.innerWidth - clickableWidth)/2) / clickableWidth;
          let yNorm = (event.clientY - (window.innerHeight - clickableHeight)/2) / clickableHeight;

          if(devlog.debug) console.log(xNorm);
          if(devlog.debug) console.log(yNorm);

          const zoomValue = {
            yaw: 0,
            pitch: 0,
            distance: lastAppliedCameraSetting.distance / 3,
            targetX: xNorm * productWidth - productWidth / 2,
            targetY: lastAppliedCameraSetting.targetY + productHeight / 2 - yNorm * productHeight,
            targetZ: 0,
          }
          zoomed = true;
          if(devlog.debug) console.log(zoomValue);
          roomleConfiguratorApi.moveCamera(zoomValue);
        }
      }
    }

    const doForceZoom = async  (xNorm,yNorm,distance,yaw=0,pitch=0) => {
      hideAfterLoadEffect();
      if(devlog.debug) console.log('force zooming');
      const params = await roomleConfiguratorApi.getParametersOfRootComponent();
      let withWall = params.find((param) => param.key === "Hintergrund").value==="Mit";
      let productWidth = (parseInt(params.find((param) => param.key === "Width").value) /1000) + (withWall ? 0.28 : 0);
      let productHeight = (parseInt(params.find((param) => param.key === "Height").value) /1000) + (withWall ? 0.4 : 0);

      const zoomValue = {
        yaw: yaw,
        pitch: pitch,
        distance: distance,
        targetX: xNorm * productWidth - productWidth / 2,
        targetY: lastAppliedCameraSetting.targetY + productHeight / 2 - yNorm * productHeight,
        targetZ: 0,
      }
      zoomed = true;
      if(devlog.debug) console.log(zoomValue);
      roomleConfiguratorApi.moveCamera(zoomValue);
    }

    const afterLoadEffect = async() => {
      const savedSettings = {...cameraSettings};
      const effectSettings = {
        yaw: -179,
        pitch: 179,
        distance: cameraSettings.distance,
        targetX: cameraSettings.targetX,
        targetY: cameraSettings.targetY,
        targetZ: cameraSettings.targetZ,
      }
      if(devlog.info) console.log(effectSettings);

      const timeout1 = 1000;
      const timeout2 = 1100;
      const timeout3 = 250;

      setTimeout(() =>
      {
        cameraSettings = {...effectSettings};

        focusCamera(true);

        setTimeout(() => {
          cameraSettings = {...savedSettings};
          focusCamera(true);
          setTimeout(() => {
            // console.log('timeout3 - show icon and text');
            showAfterLoadEffect()
          },timeout3);
          // console.log('timeout2 - move back');
        },timeout2);
        // console.log('timeout1 - initial move');
      }, timeout1);
    }

    const hideAfterLoadEffect = () => {
      afterLoadEffectVisible.value=false;
      afterLoadEffectTextVisible.value=false;
    }

    const showAfterLoadEffect = () => {
      afterLoadEffectVisible.value=true;
      afterLoadEffectTextVisible.value=true;
    }

    const reactToClick = () => {
      hideAfterLoadEffect();
      clearCustomZoomTimeouts();
    }

    const defaultArticleNumber = "200000";
    let queryParams = new URLSearchParams(window.location.search);
    let articleNumber = ref(queryParams.has('articleNumber') ? queryParams.get('articleNumber') : defaultArticleNumber);
    let forScreenshotService = (queryParams.has('forScreen') && queryParams.get('forScreen')==='true');
    let isForTest = (forScreenshotService || (document.referrer && (document.referrer.includes('test.vendeco.com') || document.referrer.includes('192.168.'))));

    let useLastRoomleConfig = true;
    let lastRoomleConfig = {...defaultRoomleConfig};
    let lastValues = "";
    let lastArticleNumber = "";
    let lastSceneStyle = "";

    let lastParameterChange;
    let currentConfig;

    const roomleInitData = {
      overrideRapi: 'live',
      enableHD: true,
      enableHDGeometry: true,
      maxVerticalCameraAngle: 30,
      maxHorizontalCameraAngle: 60,
      minHorizontalCameraAngle: -60,
      zoomDistance: 1.5,
      alwaysUseCache: true,
    };

    const mainChild = 2;
    const fovValue = 30;

    let workerQueue = [];
    const workerQueueMaxLength = 50;
    let processQueueTimeout;
    let processQueueInterval;

    let firstCamSet = true;
    let camResetAfterInteraction = false;

    const selfRemoveInterval = function() {

    }

    const scenePointerdownListener = (event) => {
      if(devlog.events) console.log('[roomleframe] event pointerdown');
      userInteraction = true;
      draggingX = event.screenX;
      draggingY = event.screenY;

      hideAfterLoadEffect();
    }
    const sceneMousedownListener = (event) => {
      if(devlog.events) console.log('[roomleframe] event pointerdown');
      userInteraction = true;
      draggingX = event.screenX;
      draggingY = event.screenY;

      hideAfterLoadEffect();
    }
    const scenePointerupListener = (event) => {
      doZoom(event);
    }
    const windowPointerupListener = (event) => {
      if (drag(event) && !zoomed) {
        if (devlog.events) console.log('[roomleframe] event pointerup');
        if (userInteraction) {
          userInteraction = false;
          camResetAfterInteraction = true;
          addToWorkerQueue(
              'resetCamera1',
              () => {
                focusCamera();
              }
          );
        }
      }
    }
    const windowMouseupListener = (event) => {
      if (drag(event) && !zoomed) {
        if (devlog.events) console.log('[roomleframe] event pointerup');
        if (userInteraction) {
          userInteraction = false;
          camResetAfterInteraction = true;
          addToWorkerQueue(
              'resetCamera1',
              () => {
                focusCamera();
              }
          );
        }
      }
    }
    const windowResizeListener = () => {
      if(devlog.events) console.log('[roomleframe] event resize');

      addToWorkerQueue(
          'resizeWindow',
          () => {
            focusCamera();
            roomleConfiguratorApi.updateSize();
            // resizeRestrictor = false;
          }
      );
    }
    const windowMouseoutListener = () => {
      if (userInteraction && !zoomed) {
        userInteraction = false;
        addToWorkerQueue(
            'resetCamera2',
            () => { focusCamera(); }
        );
      }
    }
    const windowMessageListener = async (message) => {
      // console.log("[roomleframe] message received: ", message.data);
      // window.parent.postMessage({type: 'roomle', data: 'message received'}, '*');
      // if(message.data.type === 'makeScreenshot') {
      //   if(devlog.rcvmsg) console.log('[roomleframe] receive message makeScreenshot');
      //   await getRoomleImage();
      // }

      if (message.data.type === 'initRoomle') {
        if(devlog.info) console.log('[roomleframe] receive message initRoomle');
        window.parent.postMessage({type: 'roomle', data: 'initRoomle received'}, '*');
        if(!initRunning) {
          initRunning = true;
          let actualConfig;
          if (message.data.data && message.data.data.config) {
            if(message.data.data.config.BlindColor) {
              if(BlindIdMap[message.data.data.config.BlindColor.toString()]) {
                message.data.data['BlindColor_UI'] = "vendeco:" + BlindIdMap[message.data.data.config.BlindColor.toString()];
                // delete message.data.data.config.BlindColor;
              }
              if(BlindIdMap2[message.data.data.config.BlindColor.toString()]) {
                message.data.data['BlindColor'] = "" + BlindIdMap2[message.data.data.config.BlindColor.toString()];
                // delete message.data.data.config.BlindColor;
                if(message.data.data.config.HandlingType && message.data.data.config.HandlingType.toString() === "68" && message.data.data.config.BlindWidth && message.data.data.config.BlindWidth.toString() === "56") {
                  console.log('kph50')
                  message.data.data.config.HandlingType = "7062";
                }
              }
              if(message.data.data.config.Optional_LadderTape && message.data.data.config.Optional_LadderTape.toString() === "1" && message.data.data.config.BlindLadderTapeWidth && message.data.data.config.BlindLadderTapeWidth.toString() === "9059") {
                message.data.data.config.Optional_LadderTape38 = "1";
                message.data.data.config.Optional_LadderTape = "0";
              }
            }


            if (message.data.data.config.PlisseeColor) {
              if(BasicPlisseeIdMap[message.data.data.config.PlisseeColor.toString()]) {
                // message.data.data.config['PlisseeColor'] = PlisseeIdMap[message.data.data.config.PlisseeColor.toString()];
                // delete message.data.data.config.PlisseeColor;
                delete message.data.data.config['HeightLeft'];
                delete message.data.data.config['HeightRight'];
                delete message.data.data.config['WidthTop'];
                delete message.data.data.config['WidthBottom'];
              } else {
                message.data.data.config.PlisseeColor = "vendeco_draft0:" + message.data.data.config.PlisseeColor;
              }
            }
            if (message.data.data.config.PlisseeColor2) {
              if(BasicPlisseeIdMap[message.data.data.config.PlisseeColor2.toString()]) {
                // message.data.data.config['PlisseeColor2'] = "vendeco:" + PlisseeIdMap[message.data.data.config.PlisseeColor2.toString()];
                // delete message.data.data.config.PlisseeColor2;
              } else {
                message.data.data.config.PlisseeColor2 = "vendeco_draft0:" + message.data.data.config.PlisseeColor2;
              }
            }

            if (message.data.data.config.RolloColor) {
              // console.log('adjusting rollo id');
              console.log( message.data.data.config.RolloColor);
              if(! message.data.data.config.RolloColor.toString().startsWith("vendeco:")) {
                if(RolloIdMap[ message.data.data.config.RolloColor.toString()]) {
                  message.data.data.config.RolloColor = RolloIdMap[ message.data.data.config.RolloColor.toString()];
                }
              }
              message.data.data.config['RolloColor_UI'] =  "vendeco:" + message.data.data.RolloColor;

              if(message.data.data.config['Width']) {
                // console.log('width detected 1', parseInt(message.data.data['Width']));
                if (parseInt(message.data.data.config['Width'], 10) < 2400 || forScreenshotService) {
                  // console.log('bottomrail 1 7031')
                  message.data.data.config['BottomRail'] = "7031";
                } else {
                  // console.log('bottomrail 1 7030')
                  message.data.data.config['BottomRail'] = "7030";
                }
              }

            }

            if (message.data.data.LamellaColor) {
              message.data.data['LamellaColor_UI'] = "vendeco:" + LamellaIdMap[message.data.data.LamellaColor.toString()];
              delete message.data.data.LamellaColor;
            }

            actualConfig = adjustParameters(mergeWithDefaultParameters(message.data.data.config));
          } else {
            actualConfig = adjustParameters(mergeWithDefaultParameters(roomleConfig[articleNumber.value].parameters))
          }
          lastRoomleConfig[articleNumber.value] = {...actualConfig};
          if(devlog.debug) console.log('[roomleframe] initRoomle called', timeStamp());
          await initRoomle();
          if(devlog.debug) console.log('[roomleframe] initRoomle finished', timeStamp());
          if (roomleConfiguratorApi) {
            const params = roomleConfiguratorApi.getParametersOfRootComponent()
            console.log('[roomleframe] init done', params);
            focusCamera(true);
          }
          initRunning = false;
          afterLoadEffect();
        } else {
          if(devlog.debug) console.log('[roomleframe] init already running')
        }
      }

      if (message.data.type === 'wallVisible') {
        if(devlog.rcvmsg) console.log('[roomleframe] receive message wallVisible');
        if (roomleConfiguratorApi) {
          background = message.data.data ? 'Mit' : 'Ohne';
          addToWorkerQueue(
              'toggleWall',
              () => {
                updateParameters({ 'Hintergrund': background });
                if (roomleConfiguratorApi) {
                  focusCamera(false);
                }
              }
          );
        }
      }

      if (message.data.type === 'attributeSelection') {
        if(devlog.rcvmsg) console.log('[roomleframe] receive message attributeSelection');
        console.log(message.data.data);

        if(window.parent && window.parent.parent) {
            window.parent.postMessage({
              type: 'afterSelectionFromRoomle',
              selectedAttributes: message.data.data
            }, '*')
        }

        if (roomleConfiguratorApi) {
          addToWorkerQueue(
              'updateParams',
              () => {
                if(message.data.data.BlindColor) {
                  if(BlindIdMap[message.data.data.BlindColor.toString()]) {
                    message.data.data['BlindColor_UI'] = "vendeco:" + BlindIdMap[message.data.data.BlindColor.toString()];
                    // delete message.data.data.BlindColor;
                  }
                  if(BlindIdMap2[message.data.data.BlindColor.toString()]) {
                    message.data.data['BlindColor'] = "" + BlindIdMap2[message.data.data.BlindColor.toString()];
                    // delete message.data.data.config.BlindColor;
                    if(message.data.data.HandlingType && message.data.data.HandlingType.toString() === "68" && message.data.data.BlindWidth && message.data.data.BlindWidth.toString() === "56") {
                      message.data.data.HandlingType = "7062";
                    }
                    if(message.data.data.Optional_LadderTape && message.data.data.Optional_LadderTape.toString() === "1" && message.data.data.BlindLadderTapeWidth && message.data.data.BlindLadderTapeWidth.toString() === "9059") {
                      message.data.data.Optional_LadderTape38 = "1";
                      message.data.data.Optional_LadderTape = "0";
                    }
                  }
                }

                if(message.data.data.PlisseeColor) {
                  if(BasicPlisseeIdMap[message.data.data.PlisseeColor.toString()]) {
                    message.data.data['PlisseeColor'] = "vendeco:" + BasicPlisseeIdMap[message.data.data.PlisseeColor.toString()];
                    // delete message.data.data.PlisseeColor;
                  } else if(PlisseeIdMap[message.data.data.PlisseeColor.toString()]) {
                    message.data.data['PlisseeColor'] = "vendeco_draft0:" + PlisseeIdMap[message.data.data.PlisseeColor.toString()];
                    // delete message.data.data.PlisseeColor;
                  } else {
                      message.data.data.PlisseeColor = "vendeco_draft0:"+message.data.data.PlisseeColor;
                  }
                }

                if(message.data.data.PlisseeColor2) {
                  if(BasicPlisseeIdMap[message.data.data.PlisseeColor2.toString()]) {
                    message.data.data['PlisseeColor2'] = "vendeco:" + BasicPlisseeIdMap[message.data.data.PlisseeColor2.toString()];
                    // delete message.data.data.PlisseeColor2;
                  } else if(PlisseeIdMap[message.data.data.PlisseeColor2.toString()]) {
                    message.data.data.PlisseeColor2 = "vendeco_draft0:" + PlisseeIdMap[message.data.data.PlisseeColor2.toString()];
                  } else {
                      message.data.data.PlisseeColor2 = "vendeco_draft0:"+message.data.data.PlisseeColor2;
                    }
                }

                if (message.data.data.RolloColor) {
                  // console.log('adjusting rollo id');
                  console.log( message.data.data.RolloColor);
                  if(! message.data.data.RolloColor.toString().startsWith("vendeco:")) {
                    if(RolloIdMap[ message.data.data.RolloColor.toString()]) {
                      message.data.data.RolloColor = "vendeco:" + RolloIdMap[ message.data.data.RolloColor.toString()];
                    }
                  }
                  message.data.data['RolloColor_UI'] =  message.data.data.RolloColor;

                  if(message.data.data['Width']) {
                    // console.log('width detected 2 ',parseInt(message.data.data['Width']));
                    if ((parseInt(message.data.data['Width'], 10) < 2400) || forScreenshotService) {
                      // console.log('bottomrail 2 7030')
                      message.data.data['BottomRail'] = "7031";
                    } else {
                      // console.log('bottomrail 2 7030')
                      message.data.data['BottomRail'] = "7030";
                    }
                  }
                }

                if (message.data.data.LamellaColor) {
                  message.data.data['LamellaColor_UI'] = "vendeco:" + LamellaIdMap[message.data.data.LamellaColor.toString()];
                  delete message.data.data.LamellaColor;
                }

                let differences = 0;
                let differenceList = [];
                const params = roomleConfiguratorApi.getParametersOfRootComponent().then((params) => {
                  let param;
                  let reloadTrigger = false;
                  let val = 0;

                  Object.keys(message.data.data).forEach(key => {
                    param = params.find((param) => param.key === key);

                    if (param !== undefined) {
                      if (param.type === "Decimal") {
                        val = parseInt(param.value);
                      } else {
                        val = param.value;
                      }

                      const keep = [
                        'PlisseeColor',
                        'PlisseeColor2',
                      ];
                      if (param && val.toString() !== message.data.data[key].toString() && keep.includes(key)) {
                        differences++;
                        differenceList.push(key);
                      }
                    }
                  });
                });

                updateParameters(message.data.data);

                if (roomleConfiguratorApi) {
                  focusCamera(false);
                  // setTimeout(() => {
                  //   const model = parseInt(message.data.data.PlisseeModel,10);
                  //   if( (model === 18 || model === 19)) {
                  //     // console.log(differenceList);
                  //     if (differenceList.length === 1) {
                  //       console.log('dual color model');
                  //       if (differenceList[0] === 'PlisseeColor') {
                  //         doForceZoom(0.5, 0.150, 1.8);
                  //       }
                  //       if (differenceList[0] === 'PlisseeColor2') {
                  //         doForceZoom(0.5, 0.600, 1.8);
                  //       }
                  //     }
                  //   }
                  // }, 800);
                }
              }
          );
        }
      }

      if (message.data.type === 'setArticleNumber') {
        if(devlog.rcvmsg) console.log('[roomleframe] receive message setArticleNumber');
        articleNumber.value = message.data.data;
        if (roomleConfiguratorApi) {
          killQueue();
          loadRoomleConfiguration(true);
          processQueue();
        }
      }

      if (message.data.type === 'cameraSettings') {
        if(devlog.rcvmsg) console.log('[roomleframe] receive message cameraSettings');
        cameraSettings.yaw = message.data.data.yaw
        cameraSettings.pitch = message.data.data.pitch
        cameraSettings.distance = message.data.data.distance
        cameraSettings.targetX = message.data.data.targetX;
        cameraSettings.targetY = message.data.data.targetY;
        cameraSettings.targetZ = message.data.data.targetZ;
        if (roomleConfiguratorApi) {
          applyCameraSettings(cameraSettings,true);
        }
      }

      if(message.data.type==='focusCamera') {
        cameraSettings.yaw = message.data.data.yaw
        cameraSettings.pitch = message.data.data.pitch
        cameraSettings.distance = message.data.data.distance
        cameraSettings.targetX = message.data.data.targetX
        cameraSettings.targetY = message.data.data.targetY
        cameraSettings.targetZ = message.data.data.targetZ
        if (roomleConfiguratorApi) {
          applyCameraSettings(cameraSettings,(firstCamSet || camResetAfterInteraction));
          if(firstCamSet) firstCamSet = false;
          if(camResetAfterInteraction) camResetAfterInteraction = false;
          // const params = await roomleConfiguratorApi.getParametersOfRootComponent();
          // console.log(params);
        }
      }
    }


    const removeAllListeners = () =>  {
      if(devlog.debug) console.log('removing listeners');
      document.getElementById("scene").removeEventListener("pointerdown",scenePointerdownListener);
      document.getElementById("scene").removeEventListener("mousedown",sceneMousedownListener);
      document.getElementById("scene").removeEventListener("pointerup",scenePointerupListener);


      window.removeEventListener("pointerup",windowPointerupListener);
      window.removeEventListener("mouseup",windowMouseupListener);
      window.removeEventListener("resize",windowResizeListener);
      window.removeEventListener("mouseout",windowMouseoutListener);
      window.removeEventListener("message",windowMessageListener);
    }

    onMounted(async () => {
      //send frame loaded to configurator
      killQueue();
      removeAllListeners();
      if(!frameLoaded) {
        window.parent.postMessage({type: 'roomle', data: 'frameLoaded'}, '*');
        frameLoaded = true;
      }
      if(devlog.debug) console.log('[roomleframe] mounted')
      triggerProcessQueue();

      //get default roomle configs
      roomleConfig = {...defaultRoomleConfig};

      window.addEventListener('message', windowMessageListener);

      // window.addEventListener('beforeunload', () => {
      //   removeAllListeners();
      // });
    })

    onBeforeUnmount(() => {
      removeAllListeners();
    })

    const isWallVis = () => {
      return background === 'Mit';
    }

    const addToWorkerQueue = (name, cb) => {
      if(devlog.debug) console.log('[roomleframe] adding to worker queue');
      if (workerQueue.length > workerQueueMaxLength) {
        if(devlog.debug) console.warn('[roomleframe] worker queue full - discarding last');
        workerQueue.pop();
      }
      workerQueue.push({label: name, callBack: cb});
    }

    const triggerProcessQueue = () => {
      if(devlog.debug) console.log('[roomleframe] triggering queue');
      if (processQueueInterval) window.clearInterval(processQueueInterval);
      processQueueInterval = window.setInterval(processQueue, 200);
    }

    const processQueue = () => {
      if(devlog.debug) console.log('[roomleframe] processing queue');
        if(workerQueue.length>0 && roomleConfiguratorApi) {
          if(devlog.debug) console.warn({...workerQueue});
          let job = workerQueue.shift();
          if (job && job.callBack) {
            job.callBack();
          }
        }
    }

    const killQueue = () => {
      if(devlog.info) console.log("[roomleframe] killing queue");
      if (processQueueTimeout) window.clearTimeout(processQueueTimeout);
      workerQueue = [];
    }

    const getViewPortDimensions = () => {
      return {
        "width": (lastRoomleConfig[articleNumber.value].parameters['Width'] / 1000) + (isWallVis() ? 0.28 : 0.1),
        "height": (lastRoomleConfig[articleNumber.value].parameters['Height'] / 1000) + (isWallVis() ? 0.4 : 0.1)
      }
    }

    const initPreviewImage = () => {
      const aspect = window.innerWidth/ window.innerHeight;
      const viewport = getViewPortDimensions();

      let sku = new URL(window.location.href).searchParams.get('sku');

      const defaultSkus = {
        '100000': "22S0A1M1I_1U43C0Y2W1V19-4T8C4URS",
        '200000': "25K0A0337020F170Y12-0540088C09M8",
        '300000': "28C0A1Y_2TR3H1112_4W8L71W19-56B457B4",
        '400000': "2B40AL1_1R33J2E2H2P192L-5GXC5FP0",
        '500000': "2DW002Q_4W7_4VM_233_28W-27DW28DW",
      }

      if(!sku) {
        sku = defaultSkus[articleNumber.value];
      }

      if (aspect > viewport.width / viewport.height) {
        previewImageSize.value = "auto 100%";
      } else {
        previewImageSize.value = "168.07% auto"; /*cause 1000x800 image used, crop left/right space*/
      }
      previewImage.value = "https://api.configurator.vendeco.com/data/roomlepreview/bysku/"+sku+".webp";

    }

    initPreviewImage();

    const initRoomle = async () => {
      if(devlog.info) console.log('[roomleframe] initing roomle with ', roomleConfig[articleNumber.value])

      roomleConfigurator = await RoomleSdk.getConfigurator();

      if(devlog.debug) console.log('[roomleframe] boot configurator start',timeStamp());
      roomleConfigurator.boot();
      if(devlog.debug) console.log('[roomleframe] boot configurator end',timeStamp());

      roomleConfiguratorApi = await roomleConfigurator.getApi();

      window.VAR__ROOMLEAPI = roomleConfiguratorApi;

      roomleConfiguratorApi.callbacks.onSelectionChange = (selectionMode, isRoot, hasChildren, components) => {
        if(devlog.debug) console.warn('[roomleframe] onSelectionChange', selectionMode, isRoot, hasChildren, components);
        if(devlog.debug) console.log(roomleConfigurator._configurator._configuratorContext);
      };

      roomleConfiguratorApi.callbacks.onCameraPositionChanges = () => {
        if(devlog.debug) console.log('[roomleframe] onCameraPositionChanges cam pos change');
      };

      roomleConfiguratorApi.callbacks.onBoundsUpdate = (bounds) => {
        if(devlog.debug) console.log('[roomleframe] onBoundsUpdate bounds',bounds)
      }

      roomleConfiguratorApi.callbacks.onSyncDone = (bounds) => {
        if(devlog.debug) console.log('[roomleframe] onSyncDone fired',bounds)
      }

      roomleConfiguratorApi.callbacks.onConfigurationReady = (bounds) => {
        if(devlog.debug) console.log('[roomleframe] onConfigurationReady fired',bounds)
      }

      roomleConfiguratorApi.setOverrides(roomleInitData);
      if(devlog.debug) console.log('[roomleframe] api init start on ',scene.value,timeStamp());
      await roomleConfiguratorApi.init(scene.value);
      if(devlog.debug) console.log('[roomleframe] api init end',timeStamp());

      roomleConfiguratorApi._sceneManager._pixotron.getShadowPass().shadowBiasMultiplier = 0.18;

      roomleConfiguratorApi.setBackgroundColor('#f7f7f7');
      await loadRoomleConfiguration(true);

      // roomleConfigurator._configurator._configuratorContext.selectedRuntimeComponentIds = [ roomleConfigurator._configurator._configuratorContext.rootComponentId ];
      // roomleConfigurator._configurator._configuratorContext.getSelectedRuntimeComponentIds = () => roomleConfigurator._configurator._configuratorContext.rootComponentId;
      //
      // if(devlog.debug) console.log('[roomleframe] setting active group');

      document.getElementById("scene").addEventListener('pointerdown', scenePointerdownListener);

      document.getElementById("scene").addEventListener('mousedown', sceneMousedownListener);

      document.getElementById("scene").addEventListener('pointerup', scenePointerupListener);

      window.addEventListener('pointerup', windowPointerupListener);

      window.addEventListener('mouseup', windowMouseupListener);

      // let resizeRestrictor = false;
      window.addEventListener('resize', windowResizeListener);

      window.addEventListener('mouseout', windowMouseoutListener);

      window.parent.postMessage({type: 'roomle', data: 'isLoaded'}, '*');
      window.parent.postMessage({type: 'roomle', data: 'isLoadedReceived'}, '*');
      if(devlog.debug) console.log('[roomleframe] isLoaded')

      window.setTimeout(async function() {
        isLoaded.value = true;

      },1500);
    }

    const loadRoomleConfiguration = async (fromInit) => {
      console.log("[roomleframe] loading roomle config: ", roomleConfig,articleNumber)
      let config = useLastRoomleConfig && lastRoomleConfig ? {...lastRoomleConfig[articleNumber.value]} : {...roomleConfig[articleNumber.value]};

      if(articleNumber.value==='100000') {
        if(!config.parameters.BlindColor.toString().startsWith("vendeco:")) {
          if(BlindIdMap[config.parameters.BlindColor.toString()]) {
            // console.warn('setting componentId ',config.componentId);
            config.componentId = 'vendeco:premium_jalousie_master_meta';
            config.parameters.BlindColor = BlindIdMap[config.parameters.BlindColor.toString()]
            config.parameters['BlindColor_UI'] = "vendeco:" + config.parameters.BlindColor;
          }
          // if(BlindIdMap2[config.parameters.BlindColor.toString()]) {
          //   config.componentId = 'vendeco:premium_jalousie_master_meta';
          //   config.parameters.BlindColor = BlindIdMap2[config.parameters.BlindColor.toString()]
          // }
        }
      }

      if(articleNumber.value==='200000') {
        const colNo = config.parameters.PlisseeColor.toString().replace("vendeco:","");
        if(BasicPlisseeIdMap[colNo]) {
          config.componentId = 'vendeco:Basic_Plissee_Master';
          config.parameters.PlisseeModel = "7037";
          config.parameters['PlisseeColor_UI'] = config.parameters.PlisseeColor;
          delete config.parameters['HeightLeft'];
          delete config.parameters['HeightRight'];
          delete config.parameters['WidthTop'];
          delete config.parameters['WidthBottom'];
        } else {
          console.warn(colNo,BasicPlisseeIdMap);
        }
        console.warn('setting componentId ',config.componentId);
      }

      if(articleNumber.value==='300000') {
        //console.log('ref: '+document.referrer);

          //console.log('changing rollo componentId');
          if(config.parameters["MaterialType"]==='70') {
            config.componentId = 'vendeco:rollo_master_meta02';
            // console.warn('setting componentId ',config.componentId);
          } else if(config.parameters["MaterialType"]==='71') {
            config.componentId = 'vendeco:rollo_master_doppelrollo';
            // console.warn('setting componentId ',config.componentId);
          }
          //console.log('adjusting rollo id');
          //console.log(config.parameters.RolloColor);
          if(!config.parameters.RolloColor.toString().startsWith("vendeco:")) {
            if(RolloIdMap[config.parameters.RolloColor.toString()]) {
              config.parameters.RolloColor = RolloIdMap[config.parameters.RolloColor.toString()];
            }
          }
          config.parameters['RolloColor_UI'] = "vendeco:" + config.parameters.RolloColor;

      }

      if(articleNumber.value==='400000') {
        //console.log('ref: '+document.referrer);
          //console.log('changing lamella componentId');
          config.componentId = 'vendeco:lamellenvorhang_meta';
        // console.warn('setting componentId ',config.componentId);
      }

      config = adjustParameters(config);

      if(articleNumber.value==='200000' && config.parameters["FixationKind"]==="6803") {
        config.parameters["FixationKind"] = "7034";
      }

      if(articleNumber.value==='300000' && config.parameters["InstallationPlace"]==="6318") {
        config.parameters["InstallationPlace"] = "75";
      }

      if(articleNumber.value==='300000' && config.parameters["InstallationPlace"]==="37" && !isWallVis()) {
        config.parameters["InstallationPlace"] = "4465";
      }

      // if(articleNumber.value==='400000' && getParentQueryString() && getParentQueryString().categoryId) {
      //   if(getParentQueryString().categoryId==840) {
      //     config.parameters["model"] = "6805";
      //   } else {
      //     config.parameters["model"] = "6804";
      //   }
      // }



      if(devlog.debug) console.log("[roomleframe] applying roomle config: ", config)
      if(fromInit) lastRoomleConfig[articleNumber.value] = {...config};
      if(devlog.debug) console.log('[roomleframe] loadConfiguration called',timeStamp());

      console.warn('loading config ',config);

      await roomleConfiguratorApi.loadConfiguration(JSON.stringify(config));
      if(devlog.debug) console.log('[roomleframe] loadConfiguration finish',timeStamp());
      console.log('[roomleframe] configuration loaded');
    }

    const focusCamera = (save=false) => {
      if(devlog.debug) console.log('[roomleframe] focusing camera');

      setTimeout(() => {
        const box2 = new THREE.Box3().setFromObject(RoomleConfigurator.getScene().children[mainChild]);
        const center2 = box2.getCenter(new THREE.Vector3());
        const aspect = window.innerWidth/ window.innerHeight;

        const vFOV = 30 * Math.PI / 180;
        const vFovDistToNear = 0.5 / (Math.tan( ( vFOV  / 2) ));
        const hFOV =  Math.atan((aspect /2) /  vFovDistToNear) * 2;

        /*product dim + offset*/
        const viewport = getViewPortDimensions();

        let cameraZ3;

        if (aspect > viewport.width / viewport.height) {
          cameraZ3 = (viewport.height / 2)  /  (Math.tan( ( vFOV  / 2 ) ));
        } else {
          cameraZ3 = (viewport.width / 2)  /  (Math.tan( ( hFOV  / 2 ) ));
        }

        if(save) {
          origSettings.targetX = center2.x;
          origSettings.targetY = center2.y;
          origSettings.targetZ = center2.z;
          origSettings.distance = cameraZ3;
        }

        cameraSettings.targetX = center2.x;
        cameraSettings.targetY = center2.y;
        cameraSettings.targetZ = center2.z;
        cameraSettings.distance = cameraZ3;

        // console.log(roomleConfiguratorApi.getParametersOfRootComponent());

        window.postMessage({type: 'focusCamera', data: {...cameraSettings}});
        /*window.parent.postMessage({type: 'focusCamera', data: {...cameraSettings}});*/
       }, 200);
    }

    const reset = () => {
      cameraSettings.yaw = origSettings.yaw;
      cameraSettings.pitch = origSettings.pitch;
      cameraSettings.distance = origSettings.distance;
      cameraSettings.targetX = origSettings.targetX;
      cameraSettings.targetY = origSettings.targetY;
      cameraSettings.targetZ = origSettings.targetZ;
      killQueue();
      processQueue();
    }

    const applyCameraSettings = async (vals, force = false) => {
      if (roomleConfiguratorApi && (JSON.stringify(vals) !== lastValues && !userInteraction && !isUpdating) || (force)) {
        if(devlog.info) console.log('[roomleframe] applying values: ', vals);

        vals = { ...vals };
        lastValues = JSON.stringify(vals);
        isUpdating = true;

        let offsets = {
          x:0,y:0,z:0,distance:0
        }

        if(useOffsets) offsets = calcOffsets();
        // if(devlog.info) console.log('[roomleframe] offsets are: ',offsets);

        const newValues = {
          yaw: vals.yaw / 180 * Math.PI,
          pitch: vals.pitch / 180 * Math.PI,
          distance: vals.distance + offsets.distance,
          targetX: vals.targetX + offsets.x,
          targetY: vals.targetY + offsets.y,
          targetZ: vals.targetZ + offsets.z,
        }

        if(devlog.debug) console.log('[roomleframe] new values: ',newValues);

        const cameraParameters = {
          yaw: newValues.yaw,
          pitch: newValues.pitch,
          distance: newValues.distance,
          targetX: newValues.targetX,
          targetY: newValues.targetY,
          targetZ: newValues.targetZ,
          blockOtherTweens: true
        }

        if(devlog.debug) console.log('[roomleframe] cam params are now: ', cameraParameters);
        lastAppliedCameraSetting = cameraParameters;
        await roomleConfiguratorApi.moveCamera(cameraParameters);

        isUpdating = false;
      }
    }

    // const getRoomleImage = async () => {
    //   if(devlog.info) console.log('[roomleframe] rendering image');
    //   rmlImg.value = true;
    //   const base64Image = await RoomleConfigurator.renderImage({useCurrentPerspective: true});
    //   const img = document.getElementById('rmlImg');
    //   if(devlog.debug) console.info('[roomleframe] img',base64Image);
    //   if(img) {
    //     img.src = base64Image.image;
    //     img.style = 'width: auto; height: 100%; margin:0 auto;';
    //   }
    // }

    const resetCustomZoom = async () => {
      console.warn('setting timout for custom zoom revert');
      customZoomResetTimeout = setTimeout(() => {
        console.warn('reverting custom zoom');
        applyCameraSettings(origSettings,true);
      }, customZoomResetTime);
    }

    const clearCustomZoomTimeouts = async () => {
      if (customZoomTimeout) {
        console.log('canceling customZoomTimeout');
        clearTimeout(customZoomTimeout);
      }
      if (customZoomResetTimeout) {
        console.log('canceling customZoomResetTimeout');
        clearTimeout(customZoomResetTimeout);
      }
    }

    const updateParameters = async (keyValueArray) => {
      paramUpdateRunning = true;

      if(devlog.debug) console.log("[roomleframe] update parameters", keyValueArray);
      lastParameterChange = keyValueArray;
      let config = mergeWithDefaultParameters(keyValueArray);
      config = adjustParameters(config);

      //reset optionals - if they're not selected, we don't get them from the configurator

      const optionals = {
        "100000": [
          'Optional_TopCover',
          'Optional_SideTrack',
          'Optional_Wedges',
          'Optional_LadderTape',
          'Optional_LadderTape38',
          'Optional_EspeciallyDimming'
        ],
        "200000": [
          'Optional_Stick',
          'Optional_Designhandle',
          'Optional_ComfortTrack'
        ],
        "300000": [
          'Optional_OpenCassette',
          'Optional_MountingProfile',
          'Optional_ComfortTrack',
          'Optional_SideTrack',
          'Optional_ChainWeight',
          // 'Optional_ChildProof',
          'Optional_SquareCassette',
          'Optional_Track'
        ],
        "400000": [
          'Optional_WeldedSlats',
          'SlatWelded'
        ],
        "500000": [
          'Optional_BarrierFree'
        ]
      }

      // don't change anything if we only change between wall vis/invis!
      if(!keyValueArray['Hintergrund'] && optionals[articleNumber.value].length>0) {
        if(devlog.debug) console.log('[roomleframe] checking optionals');
        optionals[articleNumber.value].forEach((opt) => {
          if(!keyValueArray[opt]) {
            console.log("[roomleframe] optional ",opt," not found - setting to 0");
            config.parameters[opt] = "0";
          } else {
            if((keyValueArray[opt]!=="1" && keyValueArray[opt]!==1)) {
              console.log("[roomleframe] optional ",opt," found with value ",keyValueArray[opt]," - setting to 0");
            } else {
              console.log("[roomleframe] optional", opt," found with value ",keyValueArray[opt]," - setting to 1");
              config.parameters[opt] = "1";
            }
          }
        })
      }

      if(articleNumber.value==='100000') {
        if(config.parameters["InstallationPlace"]==='93') { // roomle hat andere ID
          config.parameters["InstallationPlace"] = '36';
        }
        // if(config.parameters["Optional_EspeciallyDimming"]==='1' && config.parameters["InstallationPlace"]==='34') {
        //   config.parameters["Optional_TopCover"] = '1';
        // }
          if(config.parameters["BlindLadderTapeColor"]) {
              if(LadderTapeIdMap[config.parameters.BlindLadderTapeColor.toString()]) {
                  config.parameters["BlindLadderTapeColor"] = LadderTapeIdMap[config.parameters.BlindLadderTapeColor.toString()];
              }
          }

        if(config.parameters["FixationKind"] === "7036") {
          config.parameters["Optional_TopCover"] = "1";
        }
        // else {
        //   config.parameters["Optional_TopCover"] = "0";
        // }
      }

      if(articleNumber.value==='200000') {
        if(config.parameters["PlisseeModel"]==='21') { // reset comforttrack
          config.parameters["Optional_ComfortTrack"] = '0';
        }
        //enable comfort track and set correct model
        //df20 comfort
        if (config.parameters["PlisseeModel"] === "3546") {
            config.parameters["PlisseeModel"] = "21";
            config.parameters["Optional_ComfortTrack"] = "1";
        }

        //df30 comfort
        if (config.parameters["PlisseeModel"] === "6797") {
            config.parameters["PlisseeModel"] = "22";
            config.parameters["Optional_ComfortTrack"] = "1";
        }

        if(config.parameters["InstallationPlace"]==='93') { // roomle hat andere ID
          config.parameters["InstallationPlace"] = '36';
        }

        //pl11 wand -> ff
        if(config.parameters["PlisseeModel"] === "29") {
            if(config.parameters["InstallationPlace"] === "37") {
                config.parameters["InstallationPlace"] = "35";
            }
        }

        //basic klemmträger
        if(config.parameters["PlisseeModel"] === "7037") {
          if(config.parameters["FixationKind"] === "40") {
            config.parameters["FixationKind"] = "7038";
          } else if(config.parameters["FixationKind"] === "39") {
            config.parameters["FixationKind"] = "7039";
          }
        }
      }

      if(articleNumber.value==='300000') {
        if(config.parameters["InstallationPlace"]==="93") {
          config.parameters["Optional_SideTrack"] = "0";
        }
        if(config.parameters["InstallationPlace"]==="37" || config.parameters["InstallationPlace"]==="4465") {
          config.parameters["InstallationPlace"] = isWallVis() ? "37" : "4465"; //wandmontage open/closed
        }
        if(config.parameters["InstallationPlace"]==="6318") {
          config.parameters["InstallationPlace"] = "75";
        }
        if(config.parameters["Optional_OpenCassette"]==="1") {
          config.parameters["Optional_MountingProfile"] = "0";
        }
        // config.parameters["RolloColor_UI"] = 'vendeco:'+config.parameters["RolloColor"];

        if(forScreenshotService || config.parameters["HandlingType"]==="44") {
          config.parameters["HandlingType"] = "76";
        }
      }

      if(articleNumber.value==='400000') {
        if(config.parameters["Optional_WeldedSlats"]==="1") {
          config.parameters["SlatWelded"] = "1";
        } else {
          config.parameters["SlatWelded"] = "0";
        }

      }

      /*find differences in parameterslists*/
      const params = await roomleConfiguratorApi.getParametersOfRootComponent();

      let param;
      let differences = 0;
      let differenceList = [];
      let reloadTrigger = false;

      let val = 0;
      Object.keys(config.parameters).forEach(key => {
        param = params.find((param) => param.key === key);

        if (param !== undefined) {
          if (param.type === "Decimal") {
            val = parseInt(param.value);
          } else {
            val = param.value.toString();
          }

          if (param && val !== config.parameters[key]) {
            differences++;
            differenceList.push({ key: key, val: val, param: config.parameters[key] });
            if (key === "Shape") {
              reloadTrigger = true;
            }
          }
        }
      });
      console.log('[roomleframe] differences:',differences, differenceList, reloadTrigger);
      roomleConfig[articleNumber.value] = config;

      let needsRefresh = false;

      Object.keys(roomleConfig[articleNumber.value].parameters).forEach(key => {
        // console.log(key,lastRoomleConfig[articleNumber.value].parameters[key],config.parameters[key]);
        if(lastRoomleConfig[articleNumber.value].parameters[key]!==config.parameters[key]) {
          // console.log(key,"is different");
          var delay = 50;
          /*hack cord-color */
          if(key==='PlisseeColor' || key==='PlisseeColor2') {
            needsRefresh = true;
          }

          if(key!=="PlisseeColorRopeHexCode") {
            setTimeout(async () => {
              await updateSingleParameter(key, config.parameters[key]);
              if(key==="Shape" && config.parameters["PlisseeModel"]) {
                await updateSingleParameter("PlisseeModel",config.parameters[key]);
              }
              // if(key==="PlisseeModel" && config.parameters[key]==21) {
              //   await updateSingleParameter("Optional_ComfortTrack",'0');
              // }
              // if(key==="Optional_ComfortTrack" && config.parameters["PlisseeModel"]==21) {
              //   await updateSingleParameter("Optional_ComfortTrack",'0');
              // }
            }, delay);
          }

          //zooms
          if(doCustomZooms==='on' && articleNumber.value==='100000') {
            // console.warn('checking custom zoom for ', key);
            // console.warn('lzc: '+lastZoomCheck);
            const installationPlaceDistance = {
              "34": 0.8,
              "35": 0.9,
              "36": 0.9,
              "37": 1.1,
              "93": 0.9
            }

            if (key === 'Optional_SideTrack') {
              clearCustomZoomTimeouts();
              if (config.parameters[key].toString() === "1") {
                // console.warn('SIDETRACK ZOOM');
                let xFactor = 1;
                let yFactor = 1.0;
                if (isWallVis()) {
                  xFactor-=0.1;
                  yFactor-=0.1;
                }
                customZoomTimeout = setTimeout(async () => {
                  updateSingleParameter("isOpen", "1");
                  doForceZoom(xFactor, yFactor, installationPlaceDistance[config.parameters.InstallationPlace.toString()], -0.5, 1);
                  resetCustomZoom();

                }, customZoomDelay);
              } else {
                customZoomTimeout = setTimeout(async () => {
                  updateSingleParameter("Hintergrund", "Ohne");
                  window.parent.postMessage({type: 'roomle', data: 'wallOff'}, '*');
                  updateSingleParameter("isOpen", "0");
                  applyCameraSettings(origSettings, true);
                }, customZoomDelay);
              }
            }


            if (key === 'Optional_TopCover') {
              clearCustomZoomTimeouts();
              if (config.parameters[key].toString() === "1") {
                // console.warn('TOPCOVER ZOOM');
                let xFactor = 0.15;
                let yFactor = 0;
                if (isWallVis()) {
                  xFactor-=0.1;
                }
                customZoomTimeout = setTimeout(async () => {
                  updateSingleParameter("Hintergrund", "Ohne");
                  window.parent.postMessage({type: 'roomle', data: 'wallOff'}, '*');
                  doForceZoom(xFactor, yFactor, installationPlaceDistance[config.parameters.InstallationPlace.toString()], -0.5, 0);
                  resetCustomZoom();
                }, customZoomDelay);
              } else {
                customZoomTimeout = setTimeout(async () => {
                  applyCameraSettings(origSettings, true);
                }, customZoomDelay);
              }
            }


            if (key === 'Optional_EspeciallyDimming') {
              clearCustomZoomTimeouts();
              if (config.parameters[key].toString() === "1") {
                // console.warn('ESPECIALLYDIMMING ZOOM');
                let xFactor = 0.25;
                let yFactor = 0.5;
                if (isWallVis()) {
                  xFactor-=0.1;
                }
                customZoomTimeout = setTimeout(async () => {
                  updateSingleParameter("Hintergrund", "Ohne");
                  window.parent.postMessage({type: 'roomle', data: 'wallOff'}, '*');
                  doForceZoom(xFactor, yFactor, installationPlaceDistance[config.parameters.InstallationPlace.toString()], -1, 0);
                  resetCustomZoom();
                }, customZoomDelay*1.5);
              } else {
                customZoomTimeout = setTimeout(async () => {
                  applyCameraSettings(origSettings, true);
                }, customZoomDelay);
              }
            }


            if (key === 'HandlingType' && lastZoomCheck!=='InstallationPlace') {
              clearCustomZoomTimeouts();
              // console.warn('HANDLINGTYPE ZOOM');
              let xFactor = roomleConfig[articleNumber.value].parameters.HandlingSide.toString() === "45" ? 0.25 : 0.75;
              let yFactor = 0;
              if (isWallVis()) {
                if(roomleConfig[articleNumber.value].parameters.HandlingSide.toString() === "45") {
                  xFactor-=0.1;
                } else {
                  xFactor+=0.1;
                }
                yFactor = 0.15;
              }
              customZoomTimeout = setTimeout(async () => {
                updateSingleParameter("Hintergrund", "Ohne");
                window.parent.postMessage({type: 'roomle', data: 'wallOff'}, '*');
                doForceZoom(xFactor, yFactor, installationPlaceDistance[config.parameters.InstallationPlace.toString()], roomleConfig[articleNumber.value].parameters.HandlingSide.toString() === "45" ? -1 : 1, 0);
                resetCustomZoom();
              }, customZoomDelay);
            }

            if (key === 'FixationKind' && lastZoomCheck!=='InstallationPlace') {
              clearCustomZoomTimeouts();
              // console.warn('FIXATIONKIND ZOOM');
              let xFactor = roomleConfig[articleNumber.value].parameters.HandlingSide.toString() === "45" ? 0.25 : 0.75;
              let yFactor = 0;
              if (isWallVis()) {
                if(roomleConfig[articleNumber.value].parameters.HandlingSide.toString() === "45") {
                  xFactor-=0.1;
                } else {
                  xFactor+=0.1;
                }
                yFactor = 0.15;
              }
              customZoomTimeout = setTimeout(async () => {
                updateSingleParameter("Hintergrund", "Ohne");
                window.parent.postMessage({type: 'roomle', data: 'wallOff'}, '*');
                doForceZoom(xFactor, yFactor, installationPlaceDistance[config.parameters.InstallationPlace.toString()], -1, 1);
                resetCustomZoom();
              }, customZoomDelay*1.5);
            }

            lastZoomCheck = key;
          }
        }
      });

      lastRoomleConfig[articleNumber.value] = {...config};

      /*hack cord-color */
      // if(needsRefresh) {
      //   setTimeout(async () => {
      //     // await updateSingleParameter("PlisseeColorRopeHexCode", config.parameters["PlisseeColorRopeHexCode"].toLowerCase());
      //     await refreshParameters();
      //     // await updateSingleParameter(key, config.parameters[key],true);
      //   }, 1500);
      // }

      // window.dispatchEvent(new Event('resize'));
      paramUpdateRunning = false;
      window.postMessage({type: 'roomle', data: 'parameterUpdateComplete'}, '*');
      window.parent.postMessage({type: 'roomle', data: 'parameterUpdateComplete'}, '*');
      // setTimeout(async () => {
      //   // roomleConfiguratorApi._sceneManager._renderEverything();
      //   const params2 = await roomleConfiguratorApi.getParametersOfRootComponent()
      //   if (devlog.info) console.log('[roomleframe] new params', params2);
      // }, 2000);
    }

    const mergeWithDefaultParameters = (keyValueArray) => {
      if(devlog.info) console.log('[roomleframe] merging params');
      let config = {...roomleConfig[articleNumber.value]};
      config.parameters = {...roomleConfig[articleNumber.value].parameters};
      keyValueArray = {...keyValueArray};

      let excludeDim = [
        "Height",
        "Height1",
        "Height2",
        "HeightLeft",
        "HeightRight",
        "Width",
        "Width1",
        "Width2",
        "WidthTop",
        "WidthBottom",
        "Depth",
        "Quantity",
      ];

      Object.keys(keyValueArray).forEach(key => {
        if (!excludeDim.includes(key)) {
          if (keyValueArray[key] !== undefined) {
            config.parameters[key] = keyValueArray[key].toString();
          }
        }
      });

      config.parameters.Hintergrund = background;
      config.parameters = {...config.parameters};

      return config;
    }

    const calcOffsets = () => {
      if(devlog.info) console.log('[roomleframe] calculating offsets');

      const offsets = {
        distance: 0,
        x: 0,
        y: 0,
        z: 0
      };

      const currentConfig = useLastRoomleConfig ? {...lastRoomleConfig[articleNumber.value].parameters} : {...roomleConfig[articleNumber.value].parameters};

      if(articleNumber.value==='100000') {
        if (currentConfig.InstallationPlace === '34') {
          offsets.distance = isWallVis() ? 0.25 : 0;
        }

        if (currentConfig.InstallationPlace === '93' || currentConfig.InstallationPlace === '36') {
          offsets.distance = isWallVis() ? -0.15 : 0;
        }

        if (currentConfig.BlindWidth === '56') {
          offsets.distance = isWallVis() ? 0 : 0.50;
        }

        if (currentConfig.InstallationPlace === "37") { // wand
          offsets.y = isWallVis() ? 0 : 0.05;
        }

      }

      if(articleNumber.value==='200000') {
        if (currentConfig.Shape === "11") { // dachfenster
          offsets.y = 0.125;
        }

        if (currentConfig.Shape === "12") { // glasdach
          offsets.y = -0.25;
          offsets.distance = isWallVis() ? 0.20 : 0.15;
        }

        if (currentConfig.Shape === "13") { //sonderformen
          if (currentConfig.PlisseeModel === "25") { //vs4
            offsets.y = isWallVis() ? 0 : -0.075;
            offsets.distance = isWallVis() ? 0.5 : 0;
          }

          if (currentConfig.PlisseeModel === "26") { //vs5
            offsets.distance = isWallVis() ? 0.35 : 0;
          }

          if (currentConfig.PlisseeModel === "27") { //vs9d
            offsets.y = isWallVis() ? 0 : -0.15;
            offsets.distance = isWallVis() ? 0.85 : 0;

          }

          if (currentConfig.PlisseeModel === "28") { //vs10d
            offsets.y = isWallVis() ? 0 : -0.15;
            offsets.x = isWallVis() ? 0 : 0.1;
            offsets.distance = isWallVis() ? 0.85 : 0;
          }

          if (currentConfig.PlisseeModel === "23") { //fslope2
            offsets.y = isWallVis() ? 0 : -0.075;
            offsets.distance = isWallVis() ? 0.5 : 0;
          }

          if (currentConfig.PlisseeModel === "24") { //fdslope3
            offsets.y = isWallVis() ? 0 : -0.15;
            offsets.x = isWallVis() ? 0 : 0.1;
            offsets.distance = isWallVis() ? 0.85 : 0;
          }
        }
      }

      if(articleNumber.value === '300000') {
        if(currentConfig.InstallationPlace === "35" || currentConfig.InstallationPlace === "34") { // fensterflügel
          // offsets.y = isWallVis() ? 0.01 : 0.02;
          offsets.distance = isWallVis() ? 0 : 0;
        }

        if (currentConfig.InstallationPlace === "37" || currentConfig.InstallationPlace === "4465") { // wand
          offsets.y = isWallVis() ? 0.05 : 0.05;
          offsets.distance = isWallVis() ? -0.15 : 0;
        }

        if (currentConfig.InstallationPlace === "75" || currentConfig.InstallationPlace === "6318") { //klemmträger
          // offsets.y = isWallVis() ? 0.01 : 0.02;
          offsets.distance = isWallVis() ? 0 : 0;
        }

        if (currentConfig.InstallationPlace === "93") { // decke/nische
          // offsets.y = isWallVis() ? 0.01 : 0.02;
          offsets.distance = isWallVis() ? -0.30 : 0;
        }

        if (currentConfig.InstallationPlace === "759") { // dachfenster
         // offsets.y = isWallVis() ? 0 : 0.05;
         offsets.distance = isWallVis() ? 0 : 0;
        }
      }

      if(articleNumber.value === '400000' || //lamellenvorhang
          (articleNumber.value === '500000' && currentConfig.InsectProductSelection === "100") //insektenschutz plisseetür
      ) {
        offsets.y = isWallVis() ? -0.40 : -0.40;
        offsets.distance = isWallVis() ? 0.25 : 0.25;
      }

      if (articleNumber.value === '500000'  && currentConfig.InsectProductSelection !== "100") { //insektenschutz rahmen/rollo
          // offsets.y = isWallVis() ? 0.01 : 0.02;
          offsets.distance = isWallVis() ? 0 : 0;
      }

      if(forScreenshotService) {
        [
          'x',
          'y',
          'z',
          'distance',
        ].forEach((param) => {
          if(queryParams.has(param.toLowerCase())) offsets[param] = Number(queryParams.get(param.toLowerCase()));
        });
      }

      if(devlog.info) console.log('[roomleframe] using offsets', offsets);

      return offsets;
    }

    const adjustParameters = (keyValueArray) => {
      if(devlog.info) console.log('[roomleframe] adjusting params');
      let config = {...keyValueArray};
      const rollo_w_wand = "950";
      const rollo_h_wand = "1150";
      // const rollo_w_decke = "1300";
      // const rollo_h_decke = "1650";
      const rollo_w_decke = "925";
      const rollo_h_decke = "1175";
      const rollo_w_fenster = "850";
      const rollo_h_fenster = "1155";
      const rollo_w_klemm = "850";
      const rollo_h_klemm = "1150";
      switch (articleNumber.value) {
        // case '100000':
        //   config.parameters['Width'] = "650"; //standard glasfalz
        //   config.parameters['Height'] = "909";
        //
        //   if (config.parameters["InstallationPlace"] === "35") { // fensterflügel
        //
        //     if(isWallVis()) {
        //       config.parameters["Width"] = "725";
        //       config.parameters["Height"] = "1150";
        //     } else {
        //       config.parameters["Width"] = "650";
        //       config.parameters["Height"] = "909";
        //     }
        //   }
        //   if (config.parameters["InstallationPlace"] === "37") { // wand
        //     if(isWallVis()) {
        //       config.parameters["Width"] = "950";
        //       config.parameters["Height"] = "1150";
        //     } else {
        //       config.parameters["Width"] = "900";
        //       config.parameters["Height"] = "1150";
        //     }
        //   }
        //   if (config.parameters["InstallationPlace"] === "93" || config.parameters["InstallationPlace"] === "36") { // decke/nische
        //     if(config.parameters["MaterialType"] === "59") {
        //       config.parameters["Width"] = "880";
        //       config.parameters["Height"] = "1150";
        //     }
        //   }
        //   break;
        //
        // case '200000':
        //   config.parameters["Width"] = "650";
        //   config.parameters["Height"] = "909";
        //
        //   config.parameters["Width"] = "750";
        //   config.parameters["Height"] = "960";
        //
        //
        //
        //     if (config.parameters["PlisseeModel"] === "3546") {
        //       config.parameters["PlisseeModel"] = "21";
        //     }
        //
        //     if (config.parameters["PlisseeModel"] === "6797") {
        //       config.parameters["PlisseeModel"] = "22";
        //     }
        //
        //     if (config.parameters["InstallationPlace"] === "34") { // glasfalz
        //       if (isWallVis()) {
        //         config.parameters["Width"] = "725";
        //         config.parameters["Height"] = "1200";
        //         config.parameters["HeightLeft"] = "600";
        //         config.parameters["HeightRight"] = "1200";
        //         config.parameters["WidthTop"] = "300";
        //         config.parameters["WidthBottom"] = "725";
        //       } else {
        //         config.parameters["Width"] = "650";
        //         //config.parameters["Width"] = "1300";
        //         config.parameters["Height"] = "909";
        //         config.parameters["HeightLeft"] = "450";
        //         config.parameters["HeightRight"] = "909";
        //         config.parameters["WidthTop"] = "200";
        //         config.parameters["WidthBottom"] = "650";
        //       }
        //     }
        //
        //     if (config.parameters["InstallationPlace"] === "35") { // fensterflügel
        //       if (isWallVis()) {
        //         config.parameters["Width"] = "750";
        //         config.parameters["Height"] = "1250";
        //         config.parameters["HeightLeft"] = "625";
        //         config.parameters["HeightRight"] = "1250";
        //         config.parameters["WidthTop"] = "375";
        //         config.parameters["WidthBottom"] = "750";
        //       } else {
        //         config.parameters["Width"] = "650";
        //         config.parameters["Height"] = "909";
        //         config.parameters["HeightLeft"] = "400";
        //         config.parameters["HeightRight"] = "909";
        //         config.parameters["WidthTop"] = "500";
        //         config.parameters["WidthBottom"] = "650";
        //       }
        //     }
        //
        //     if (config.parameters["InstallationPlace"] === "36") { // fensternische
        //       if (isWallVis()) {
        //         config.parameters["Width"] = "1000";
        //         config.parameters["Height"] = "1350";
        //       } else {
        //         config.parameters["Width"] = "650";
        //         config.parameters["Height"] = "909";
        //       }
        //     }
        //
        //     if (config.parameters["InstallationPlace"] === "37") { // wand
        //       if (isWallVis()) {
        //         config.parameters["Width"] = "1100";
        //         config.parameters["Height"] = "1450";
        //       } else {
        //         config.parameters["Width"] = "650";
        //         config.parameters["Height"] = "909";
        //       }
        //     }
        //   break;

        case '100000':
        case '200000':
        case '300000':
          if(config.parameters["InstallationPlace"] === "34") { //glasleistenrollo
            if(articleNumber.value==='300000' && config.parameters["RolloModel"]!=="766") {
              config.parameters["RolloModel"] = "766";
            }

            if (isWallVis()) {
              config.parameters["Width"] = rollo_w_fenster;
              config.parameters["Height"] = rollo_h_fenster;
            } else {
              config.parameters["Width"] = "800";
              config.parameters["Height"] = "1250";
            }

          }

          if(config.parameters["InstallationPlace"] === "35") { // fensterflügel
            if (isWallVis()) {
              config.parameters["Width"] = rollo_w_fenster;
              config.parameters["Height"] = rollo_h_fenster;
            } else {
              config.parameters["Width"] = "800";
              config.parameters["Height"] = "1200";
            }
          }

          if(config.parameters["InstallationPlace"] === "37" || config.parameters["InstallationPlace"] === "4465") { // wand

            if (isWallVis()) {
              config.parameters["Width"] = rollo_w_wand;
              config.parameters["Height"] = rollo_h_wand;
            } else {
              config.parameters["Width"] = "800";
              config.parameters["Height"] = "1200";
            }
          }

          if(config.parameters["InstallationPlace"] === "6318") { // klemmträger
            if (isWallVis()) {
              config.parameters["Width"] = rollo_w_klemm;
              config.parameters["Height"] = rollo_h_klemm;
            } else {
              config.parameters["Width"] = "800";
              config.parameters["Height"] = "1100";
            }
          }

          if(config.parameters["InstallationPlace"] === "93") { // decke/nische
            if (isWallVis()) {
              config.parameters["Width"] = rollo_w_decke;
              config.parameters["Height"] = rollo_h_decke;
            } else {
              config.parameters["Width"] = "800";
              config.parameters["Height"] = "1200";
            }
          }

          if(config.parameters["InstallationPlace"] === "759") { //df-rollo
            if(articleNumber.value==='300000' && config.parameters["RolloModel"]!=="765") {
              config.parameters["RolloModel"] = "765";
            }
          }

          // if(config.parameters["Shape"] === "13") {
          //           config.parameters["Width"] = rollo_w_fenster;
          //           config.parameters["Height"] = rollo_h_fenster;
          //           config.parameters["HeightLeft"] = "925";
          //           config.parameters["HeightRight"] = rollo_h_fenster;
          //           // config.parameters["WidthTop"] = "300";
          //           // config.parameters["WidthBottom"] = "725";
          // }
          break;

        case '400000':
          config.parameters["Width"] = "1500";
          config.parameters["Height"] = "2000";
          if(config.parameters["Shape"] === "87") {
            config.parameters["HeightLeft"] = "1750";
            config.parameters["HeightRight"] = "2000";
          }
          if(config.parameters["Shape"] === "88") {
            config.parameters["HeightLeft"] = "2000";
            config.parameters["HeightRight"] = "1750";
          }
          break;

        case '500000':
          if (config.parameters["InsectProductSelection"] === "100") {
            config.parameters["InstallationPlace"] = "783";
            config.parameters["Width"] = "900";
            config.parameters["Height"] = "1800";
          } else {
            config.parameters["InstallationPlace"] = "36";
              config.parameters["Width"] = rollo_w_klemm;
              config.parameters["Height"] = rollo_h_klemm;
          }
          break;

        default:
          break;
      }

      if(forScreenshotService) {
        [
          'Width',
          'WidthTop',
          'WidthBottom',
          'Height',
          'HeightLeft',
          'HeightRight',
        ].forEach((param) => {
          console.warn('----------------FORSCREEN DETECTED------');
          if(queryParams.has(param.toLowerCase())) {config.parameters[param] = queryParams.get(param.toLowerCase()).toString(10);
          }
        });
      }

      return config;
    }

    const updateSingleParameter = async (paramName, value, force=false) => {
      if(devlog.info) console.log("[roomleframe] update single parameter",paramName,value);
      const params = await roomleConfiguratorApi.getParametersOfRootComponent();
      const param = params.find((param) => param.key === paramName);

      if (param !== undefined) {
        let val = 0;

        if (param.type === "Decimal") {
          val = parseInt(param.value);
        } else {
          val = param.value;
        }

        if (value !== val || force) {
          if(devlog.debug)  console.log("setting "+param.key+" to "+value.toString());
          await roomleConfiguratorApi.setParameter(param, value.toString());
        }
      } else {
        console.warn('unknown param: ',paramName,params)
      }
      window.dispatchEvent(new Event('resize'));
    }

    const refreshParameters = async () => {
      if(devlog.info) console.log("[roomleframe] refresh parameters");
      /*workaround to ensure update of all parameters*/
      const params = await roomleConfiguratorApi.getParametersOfRootComponent();
      const param = params.find((param) => param.key === "Hintergrund");
      roomleConfiguratorApi.setParameter(param, background);
    }

    const timeStamp = function() {
      var currentdate = new Date();
      var datetime =
          currentdate.getHours() + ":"
          + currentdate.getMinutes() + ":"
          + currentdate.getSeconds() + ".";
          + currentdate.getMilliseconds();
      return datetime;
    }

    return {
      scene,
      values: cameraSettings,
      applyValues: applyCameraSettings,
      sceneStyle,
      reset,
      roomleConfig,
      articleNumber,
      isLoaded,
      background,
      rmlImg,
      doZoom,
      previewImage,
      previewImageSize,
      afterLoadEffectVisible,
      afterLoadEffectTextVisible,
      isForTest,
      forScreenshotService,
      clearCustomZoomTimeouts,
      reactToClick
    };
  }
};
</script>

<style scoped>
.container {
  display:flex;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  align-content:center;
  background:#d1d0d1;
}

.roomle {
  position:relative;
  width: 100%;
  height: 100%;
  /*pointer-events: none;*/
  margin:auto;
}

.scene {
  top:0;
  left:0;
  position:absolute;
  width:100%;
  height:100%;
  z-index: 0;

}

/**horizontal center line **/
/*.scene-center:before {
  z-index:99999;
  content:"";
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  border-top: 1px dashed black;
  transform: translateY(-50%);
  opacity: 0.5;
  pointer-events: none;
}*/

/**vertical center line **/
/*.scene-center:after {*/
/*  z-index:99999;*/
/*  content:"";*/
/*  position: absolute;*/
/*  top: 0;*/
/*  bottom: 0;*/
/*  left: 50%;*/
/*  border-left: 1px dashed black;*/
/*  transform: translate(-50%);*/
/*  opacity: 0.5;*/
/*  pointer-events: none;*/
/*}*/

/**outline **/
/*.scene-outline:after {*/
/*  z-index:99999;*/
/*  content:"";*/
/*  position: absolute;*/
/*  top: 15px;*/
/*  bottom: 15px;*/
/*  left: 15px;*/
/*  right: 15px;*/
/*  border: 1px dashed black;*/
/*  opacity: 0.5;*/
/*  pointer-events: none;*/
/*}*/

.preview {

  width:100%;
  height:100%;
  z-index: 998;

  position: absolute;
  top:0px;


  background-repeat: no-repeat;
  background-position: center center;
  background-color: #f7f7f7;

}

.loaderContainer {
  position:absolute;
  top:0;
  left:0;
  z-index:999;
  width:100%;
  height:100%;

  display:flex;
  justify-content:center;
  align-content:center;
  justify-items:center;
  align-items:center;
  overflow:hidden;
}

.loader {
  color: #A0A0A0;
  font-size: 45px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  position: absolute;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load6 1.7s infinite ease, round 1.7s infinite ease;
  animation: load6 1.7s infinite ease, round 1.7s infinite ease;
}

@-webkit-keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.087em -0.825em 0 -0.42em, -0.173em -0.812em 0 -0.44em, -0.256em -0.789em 0 -0.46em, -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.338em -0.758em 0 -0.42em, -0.555em -0.617em 0 -0.44em, -0.671em -0.488em 0 -0.46em, -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow: 0 -0.83em 0 -0.4em, -0.377em -0.74em 0 -0.42em, -0.645em -0.522em 0 -0.44em, -0.775em -0.297em 0 -0.46em, -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow: 0 -0.83em 0 -0.4em, 0 -0.83em 0 -0.42em, 0 -0.83em 0 -0.44em, 0 -0.83em 0 -0.46em, 0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.miniloader {
  z-index:888;
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  background: linear-gradient(to right, #ff5d10 10%, rgba(255, 255, 255, 0) 42%);
  position: absolute;
  left:0;
  top:0;
  bottom:0;
  right:0;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}
.miniloader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: '';
}
.miniloader:after {
  background: transparent;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: '';
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.afterloadeffect_div {
  /*border:1px solid red;*/
  margin:5px;
  text-align:center;
  width: 200px;
  height: 70px;
  /*border-radius: 50%;*/
  /*border: 1px solid white;*/
  /*animation: afterloadeffect_glow 0.75s infinite alternate;*/
  z-index: 9998;
  position: absolute;
  left:calc(50% - 100px);
  top:calc(50% - 35px);
  bottom:0;
  right:0;

  background: transparent url("~@/assets/tap.svg") no-repeat;
  background-size: 40px 40px;
  background-position: top center;
  cursor:pointer;
}

.afterloadeffect_position {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  /*border: 1px solid white;*/
  animation: afterloadeffect_glow 0.5s infinite alternate;
  z-index: 9999;
  position: absolute;
  left:91px;
  top:1px;
  bottom:0;
  right:0;
  cursor:pointer;
}

.aftereffect_text {
  position:absolute;
  bottom:0;
  text-align:center;
  width:100%;
  font-family:'Roboto','OpenSans','Helvetica Neue',Helvetica,Arial,sans-serif;
  font-size: 20px;
  font-weight:normal;
  color: white;
  text-shadow:1px 1px 2px black;
  cursor:pointer;
}

@keyframes afterloadeffect_glow {
  from {
    box-shadow: 0 0 0 -3px #ffffff;
  }
  to {
    box-shadow: 0 0 3px 3px #ffffff;
  }
}

</style>
